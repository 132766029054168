import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Text,
  Stack,
  FormControl,
  Button,
  Skeleton,
  VStack,
} from 'native-base';
import Trans from '../../Components/Trans';
import Drawer from '../../../Libs/Drawer';
import DateTimePicker from '../../../Libs/DateTimePicker';
import TranslationAction from '../../../Actions/TranslationAction';
import APIAction from '../../../Actions/APIAction';
import GeneralAction from '../../../Actions/GeneralAction';
import RequestCacheAction from '../../../Actions/RequestCacheAction';
import ClientAction from '../../../Actions/ClientAction';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import {v4 as uuidv4} from 'uuid';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import {NumberInput, Select} from '../../../Libs/CustomInputs';
import ProjectShiftEditBox from './ProjectShiftEditBox';
import {useRecoilState} from 'recoil';
import ShiftEditPaneAppendState from '../../../Recoil/ShiftEditPaneAppendState';

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const ProjectShiftEditPane = props => {
  const [shiftPaneAppend, setShiftPaneAppend] = useRecoilState(
    ShiftEditPaneAppendState,
  );
  return (
    <>
      <Drawer
        open={props.open}
        onClose={() => props.onClose()}
        style={{display: shiftPaneAppend === null ? 'flex' : 'none'}}>
        <ProjectShiftEditBox
          open={props.open}
          project={props.project}
          shift={props.shift}
          showEmployeesOnly={props.showEmployeesOnly}
          onClose={props.onClose}
          reload={props.reload}
          suggestedEmployee={props.suggestedEmployee}
        />
      </Drawer>
    </>
  );
};

export default ProjectShiftEditPane;
