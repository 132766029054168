import React, {useEffect, useState, useCallback} from 'react';
import {
  Box,
  Button,
  FormControl,
  Switch,
  Heading,
  HStack,
  Stack,
  Text,
} from 'native-base';
import AppContainer from '../Components/AppContainer';
import {useIsFocused} from '@react-navigation/native';
import APIAction from '../../Actions/APIAction';
import Trans from '../Components/Trans';
import Placeholder from '../Components/Placeholder';
import {v4 as uuidv4} from 'uuid';
import UserAction from '../../Actions/UserAction';
import Details from './Details';
import GeneralAction from '../../Actions/GeneralAction';
import {StyleSheet} from 'react-native';
import main from '../../Assets/Styles/main.json';
import DateTimePicker from '../../Libs/DateTimePicker';
import {NumberInput, Select} from '../../Libs/CustomInputs';
import TranslationAction from '../../Actions/TranslationAction';
import RowItem from '../Job/RowItem';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const mainStyle = StyleSheet.create(main);
const ApproveJob = props => {
  // Init
  let id = '';
  const isFocused = useIsFocused();

  let [jobPreviewElement, setJobPreviewElement] = useState([]);
  let [job, setJob] = useState(false);
  let [user, setUser] = useState([]);

  let [startDate, setStartDate] = useState(new Date());
  let [endDate, setEndDate] = useState(new Date());
  let [startHour, setStartHour] = useState(new Date());
  let [endHour, setEndHour] = useState(new Date());
  let [originalStartHour, setOriginalStartHour] = useState(new Date());
  let [originalEndHour, setOriginalEndHour] = useState(new Date());
  let [clockedInAt, setclockedInAt] = useState(null);
  let [clockedOutAt, setclockedOutAt] = useState(null);
  let [breakDuration, setBreakDuration] = useState(0);
  let [clockedBreakDuration, setClockedBreakDuration] = useState(0);
  let [noShow, setNoShow] = useState(0);
  let [onlyEditReasonOfAbsence, setOnlyEditReasonOfAbsence] = useState(0);
  let [reasonOfAbsence, setReasonOfAbsence] = useState('Ziekte');
  let [translations, setTranslations] = useState({});
  let [firstLoad, setFirstLoad] = useState(false);

  // Get id
  if (props.route && props.route.params) {
    if (props.route.params.id) {
      id = props.route.params.id;
    }
  }
  if (props.id) {
    id = props.id;
  }
  const onFirstLoad = () => {
    const init = async () => {
      /* TRANLATIONS */
      setTranslations(await TranslationAction.translateInLine(['Select']));
    };
  };

  useEffect(() => {
    if (!firstLoad) {
      onFirstLoad();
      setFirstLoad(true);
    }

    /**
     * Confirm hours
     *
     * @param confirm
     * @returns {Promise<void>}
     */
    const confirmHours = async (confirm = true) => {
      // Init
      let url = '/api/employee_shifts/' + job.id;

      // Format hours
      let arrivedAt = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startHour.getHours(),
        startHour.getMinutes(),
      );
      let leftAt = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        endHour.getHours(),
        endHour.getMinutes(),
      );
      let startOn = new Date(job.shift.startOn);

      //make sure start on date is always correct
      let formatter = new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'europe/brussels',
      });

      let refDate = formatter.format(new Date(startOn));
      let refStartOn = formatter.format(new Date(arrivedAt));
      let refEndOn = formatter.format(new Date(leftAt));

      let arrivedAtOffset = GeneralAction.tzOffsetToTz(
        arrivedAt.getTimezoneOffset(),
      );
      let leftAtOffset = GeneralAction.tzOffsetToTz(leftAt.getTimezoneOffset());

      if (refDate !== refStartOn || refDate !== refEndOn) {
        arrivedAt = new Date(arrivedAt.setDate(startOn.getDate()));
        arrivedAt = new Date(arrivedAt.setMonth(startOn.getMonth()));
        arrivedAt = new Date(arrivedAt.setFullYear(startOn.getFullYear()));
        arrivedAt = GeneralAction.changeTimezone(arrivedAt, arrivedAtOffset);
        leftAt = new Date(leftAt.setDate(startOn.getDate()));
        leftAt = new Date(leftAt.setMonth(startOn.getMonth()));
        leftAt = new Date(leftAt.setFullYear(startOn.getFullYear()));
        leftAt = GeneralAction.changeTimezone(leftAt, leftAtOffset);
      }

      // Modify left at in case shift ended on the next day (e.g. start at 23h -> end at 05h next day
      while (arrivedAt > leftAt) {
        leftAt = new Date(leftAt.getTime() + 24 * 60 * 60 * 1000);
      }

      if (arrivedAt < leftAt) {
        let data = {
          approver: '/api/users/' + user.userId,
          clientConfirmedAt: new Date(),
          clientConfirmed: confirm,
          breakDuration: noShow ? null : breakDuration,
          reasonOfAbsence: noShow ? reasonOfAbsence : null,
        };

        // Save hours through api
        if (!onlyEditReasonOfAbsence) {
          data.arrivedAt = noShow ? null : arrivedAt;
          data.leftAt = noShow ? null : leftAt;
        }

        // Update employee shift
        await APIAction.request({method: 'PATCH', url: url, body: data});

        // Go back (and refresh) to main page app
        if (confirm) {
          GeneralAction.toast('success', <Trans>Job has been approved</Trans>);
        } else {
          GeneralAction.toast('success', <Trans>Job has been denied</Trans>);
        }
        props.navigation.replace('Base');
      } else {
        // Error if start date is after end date
        GeneralAction.toast(
          'error',
          <Trans>End date cannot be earlier than start date</Trans>,
        );
        props.stopApproving();
      }
    };

    if (props.trigger) {
      confirmHours();
    }

    const fetchData = async () => {
      // Init
      let element = [];

      // Get user
      let dbUser = await UserAction.getUser();
      setUser(dbUser);

      if (id && !job && !firstLoad) {
        // Get job
        let apiJob = await APIAction.request({
          method: 'get',
          url: '/api/employee_shifts/' + id,
        });

        if (apiJob && (apiJob.deletedAt || apiJob.multiDayDeletedAt)) {
          setOnlyEditReasonOfAbsence(true);
          setNoShow(true);
        }

        // Update default dates
        if (apiJob.shift) {
          if (apiJob.shift.deletedAt || apiJob.shift.multiDayDeletedAt) {
            setOnlyEditReasonOfAbsence(true);
            setNoShow(true);
          }

          if (apiJob.arrivedAt) {
            setStartHour(new Date(apiJob.arrivedAt));
            setOriginalStartHour(new Date(apiJob.shift.startOn));
            setStartDate(new Date(apiJob.arrivedAt));
          } else if (apiJob.clockedInAt) {
            setStartHour(new Date(apiJob.clockedInAt));
            setOriginalStartHour(new Date(apiJob.shift.startOn));
            setStartDate(new Date(apiJob.clockedInAt));
          } else {
            setStartHour(new Date(apiJob.shift.startOn));
            setOriginalStartHour(new Date(apiJob.shift.startOn));
            setStartDate(new Date(apiJob.shift.startOn));
          }

          if (apiJob.leftAt) {
            setEndHour(new Date(apiJob.leftAt));
            setOriginalEndHour(new Date(apiJob.shift.endOn));
            setEndDate(new Date(apiJob.leftAt));
          } else if (apiJob.clockedOutAt) {
            setEndHour(new Date(apiJob.clockedOutAt));
            setOriginalEndHour(new Date(apiJob.shift.endOn));
            setEndDate(new Date(apiJob.clockedOutAt));
          } else {
            setEndHour(new Date(apiJob.shift.endOn));
            setOriginalEndHour(new Date(apiJob.shift.endOn));
            setEndDate(new Date(apiJob.shift.endOn));
          }

          if (apiJob.clockedInAt) {
            setclockedInAt(new Date(apiJob.clockedInAt));
          }
          if (apiJob.clockedOutAt) {
            setclockedOutAt(new Date(apiJob.clockedOutAt));
          }
          //   clockedBreakDuration is set by the employee
          if (apiJob.clockedBreakDuration) {
            setBreakDuration(apiJob.clockedBreakDuration);
            setClockedBreakDuration(apiJob.clockedBreakDuration);
          }
          if (apiJob.breakDuration) {
            // breakDuration is set by the employer, if it is set, we overwrite the clockedBreakDuration
            setBreakDuration(apiJob.breakDuration);
          }

          //TODO: set onShow from request
        }

        setJob(apiJob);

        // Get job element
        element.push(
          <RowItem key={uuidv4()} job={apiJob} headerStyle={true} />,
        );
        setJobPreviewElement(element);
      }
    };

    fetchData();
  }, [
    firstLoad,
    job,
    isFocused,
    id,
    props,
    breakDuration,
    endDate,
    endHour,
    noShow,
    reasonOfAbsence,
    startDate,
    startHour,
    clockedInAt,
    clockedOutAt,
    user,
    onlyEditReasonOfAbsence,
  ]);

  return (
    <>
      <Box style={mainStyle.boxItemVertical}>
        {jobPreviewElement.length < 1 && id ? (
          <Box key={uuidv4()} style={[{marginBottom: 10}]}>
            <Placeholder key={uuidv4()} />
          </Box>
        ) : (
          <>
            {jobPreviewElement}
            <Heading style={mainStyle.mediumTitle}>
              <Trans>Hours to approve</Trans>
            </Heading>
            <Stack
              style={{width: '100%', paddingTop: 10}}
              direction={{base: 'column', md: 'row'}}>
              <Box style={{flex: 1, padding: 10}}>
                <HStack style={{alignItems: 'center'}}>
                  <Text>
                    <Trans>Start</Trans>
                  </Text>
                </HStack>
                <DateTimePicker
                  disabled={noShow || onlyEditReasonOfAbsence}
                  mode={'time'}
                  value={startHour}
                  onChange={value => {
                    setStartHour(value);
                  }}
                />
                <Text
                  style={[
                    mainStyle.timeLabel,
                    {
                      position: 'absolute',
                      bottom: 18,
                      right: 15,
                      paddingVertical: 2,
                      paddingHorizontal: 10,
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  ]}>
                  <Icon
                    color={'#00aaff'}
                    name={'calendar'}
                    size={15}
                    style={{marginRight: 5}}
                  />
                  {GeneralAction.formatTime(originalStartHour)}
                </Text>
                {clockedInAt && (
                  <Text
                    style={[
                      mainStyle.timeLabel,
                      {
                        position: 'absolute',
                        bottom: 18,
                        right: 87,
                        paddingVertical: 2,
                        paddingHorizontal: 10,
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    ]}>
                    <Icon
                      color={'#00aaff'}
                      name={'hourglass-start'}
                      size={15}
                      style={{marginRight: 5}}
                    />
                    {GeneralAction.formatTime(clockedInAt)}
                  </Text>
                )}
              </Box>
              <Box style={{flex: 1, padding: 10}}>
                <HStack style={{alignItems: 'center'}}>
                  <Text>
                    <Trans>End</Trans>
                  </Text>
                </HStack>
                <DateTimePicker
                  disabled={noShow || onlyEditReasonOfAbsence}
                  mode={'time'}
                  value={endHour}
                  onChange={value => {
                    setEndHour(value);
                  }}
                />
                <Text
                  style={[
                    mainStyle.timeLabel,
                    {
                      position: 'absolute',
                      bottom: 18,
                      right: 15,
                      paddingVertical: 2,
                      paddingHorizontal: 10,
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  ]}>
                  <Icon
                    color={'#00aaff'}
                    name={'calendar'}
                    size={15}
                    style={{marginRight: 5}}
                  />
                  {GeneralAction.formatTime(originalEndHour)}
                </Text>
                {clockedInAt && !clockedOutAt && (
                  <Text
                    style={[
                      mainStyle.timeLabel,
                      {
                        position: 'absolute',
                        bottom: 18,
                        right: 87,
                        paddingVertical: 2,
                        paddingHorizontal: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        opacity: 0.5,
                      },
                    ]}>
                    <Icon
                      color={'#00aaff'}
                      name={'hourglass-half'}
                      size={15}
                      style={{marginRight: 5}}
                    />
                    <Trans>Not finished</Trans>
                  </Text>
                )}
                {clockedOutAt && (
                  <Text
                    style={[
                      mainStyle.timeLabel,
                      {
                        position: 'absolute',
                        bottom: 18,
                        right: 87,
                        paddingVertical: 2,
                        paddingHorizontal: 10,
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    ]}>
                    <Icon
                      color={'#00aaff'}
                      name={'hourglass-end'}
                      size={15}
                      style={{marginRight: 5}}
                    />
                    {GeneralAction.formatTime(clockedOutAt)}
                  </Text>
                )}
              </Box>
            </Stack>
            <Stack
              style={{width: '100%'}}
              direction={{base: 'column', md: 'row'}}>
              <Box style={{flexGrow: 1, padding: 10}}>
                <HStack style={{alignItems: 'center'}}>
                  <Text>
                    <Trans>Break duration (in minutes)</Trans>
                  </Text>
                </HStack>
                <NumberInput
                  style={
                    noShow || onlyEditReasonOfAbsence
                      ? {
                          backgroundColor: '#F7F7F7',
                          color: '#CCC',
                          cursor: 'not-allowed',
                        }
                      : {}
                  }
                  _hover={
                    noShow || onlyEditReasonOfAbsence
                      ? {borderColor: '#CECECE'}
                      : {}
                  }
                  key={'break_duration'}
                  min={0}
                  max={300}
                  value={breakDuration}
                  hideButtons={true}
                  isReadOnly={noShow || onlyEditReasonOfAbsence}
                  onChange={val => {
                    setBreakDuration(val);
                  }}
                />
                <Text style={[mainStyle.smallText]}>
                  {job.employee.user.firstName}
                  {'\u00A0'}
                  {job.employee.user.lastName}
                  {'\u00A0'}
                  <Trans>clocked</Trans>:{'\u00A0'}
                  {clockedBreakDuration}
                  {'\u00A0'}
                  <Trans>minutes</Trans>
                </Text>
              </Box>
              <Box style={{flexGrow: 0, padding: 10}}>
                <HStack>
                  <Text>
                    <Trans>Absent</Trans>
                  </Text>
                </HStack>
                <Switch
                  style={{marginTop: 7}}
                  onTrackColor={'#00AAFF'}
                  disabled={onlyEditReasonOfAbsence}
                  defaultIsChecked={noShow || onlyEditReasonOfAbsence}
                  onValueChange={val => {
                    setNoShow(val);
                  }}
                />
              </Box>
              <Box style={{flexGrow: 1, padding: 10}}>
                <HStack style={{alignItems: 'center'}}>
                  <Text
                    style={{
                      color:
                        noShow || onlyEditReasonOfAbsence ? 'black' : 'grey',
                    }}>
                    <Trans>Reason of absence</Trans>
                  </Text>
                </HStack>
                <Select
                  placeholder={translations['Select']}
                  selectedValue={reasonOfAbsence}
                  onValueChange={value => {
                    setReasonOfAbsence(value);
                  }}
                  isDisabled={!noShow && !onlyEditReasonOfAbsence}>
                  <Select.Item label={'Ziekte'} value={'Ziekte'} />
                  <Select.Item
                    label={'Toegestane afwezigheid'}
                    value={'Toegestane afwezigheid'}
                  />
                  <Select.Item
                    label={'Ongewettigde afwezigheid'}
                    value={'Ongewettigde afwezigheid'}
                  />
                </Select>
              </Box>
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default ApproveJob;
