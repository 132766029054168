import React, {useCallback, useEffect, useState, useRef} from 'react';
import {
  Box,
  Text,
  Stack,
  FormControl,
  Button,
  Skeleton,
  VStack,
  HStack,
  Checkbox,
  Input,
  Actionsheet,
  Pressable,
} from 'native-base';
import Trans from '../../Components/Trans';
import DateTimePicker from '../../../Libs/DateTimePicker';
import TranslationAction from '../../../Actions/TranslationAction';
import APIAction from '../../../Actions/APIAction';
import GeneralAction from '../../../Actions/GeneralAction';
import RequestCacheAction from '../../../Actions/RequestCacheAction';
import ClientAction from '../../../Actions/ClientAction';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import {v4 as uuidv4} from 'uuid';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import {NumberInput, Select} from '../../../Libs/CustomInputs';
import EmployeeSelect from './EmployeeSelect';
import UserAction from '../../../Actions/UserAction';
import {Alert} from 'react-native';

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const ProjectShiftEditBox = props => {
  const [firstLoad, setFirstLoad] = useState(false),
    [formData, setFormData] = useState(null),
    [translations, setTranslations] = useState({}),
    [positions, setPositions] = useState([]),
    [departments, setDepartments] = useState([]),
    [submitLoading, setSubmitLoading] = useState(false),
    [addEmployeeSelects, setAddEmployeeSelects] = useState([]),
    publishShift = useRef(true),
    formDataRef = useRef(true),
    newShift = useRef(false),
    employee = useRef(null),
    originalEmployees = useRef(null),
    acceptedEmployees = useRef([]),
    otherEmployees = useRef([]),
    [language, setLanguage] = useState(null);
  const updateAddEmployees = useCallback(() => {
    let quantity = formDataRef.quantity;
    let selects = [];

    for (let i = 1; i <= quantity; i++) {
      selects.push(
        <EmployeeSelect
          translations={translations}
          i={i}
          updateAddEmployees={updateAddEmployees}
          employee={employee}
          acceptedEmployees={acceptedEmployees.current}
          otherEmployees={otherEmployees.current}
          formDataRef={formDataRef}
          shift={props.shift}
        />,
      );
    }

    setAddEmployeeSelects(selects);
  }, [
    translations,
    formDataRef,
    acceptedEmployees,
    otherEmployees,
    props.shift,
  ]);

  const onFirstLoad = useCallback(() => {
    const init = async () => {
      let selectedLanguage = await TranslationAction.getSelectedLanguage();
      setLanguage(selectedLanguage);

      /* TRANLATIONS */
      setTranslations(
        await TranslationAction.translateInLine([
          'Select',
          'Minimum',
          'length',
          'hours',
        ]),
      );

      /* REQUEST QUEUE */
      let requestQueue = [];
      requestQueue.push(
        APIAction.request({method: 'GET', url: '/api/positions', cache: false}),
      );
      requestQueue.push(
        APIAction.request({
          method: 'GET',
          url: '/api/departments',
          cache: false,
        }),
      );
      let employeeParams = {
        checkErrors: 1,
      };

      if (props.shift) {
        employeeParams['startOn'] = props.shift.startOn;
        employeeParams['endOn'] = props.shift.endOn;
        employeeParams['shiftId'] = props.shift.id;
      }

      if (!props.showEmployeesOnly) {
        employeeParams['limit'] = 100;
      }
      requestQueue.push(
        APIAction.request({
          method: 'GET',
          url: '/api/employees',
          cache: false,
          params: employeeParams,
        }),
      );
      if (props.shift) {
        requestQueue.push(
          APIAction.request({
            method: 'GET',
            url: `/api/job_promo_accepteds?shift=${props.shift.id}`,
          }),
        );
      }
      let requestQueueRes = await Promise.all(requestQueue);

      /* POSITIONS */
      let apiPositions = requestQueueRes[0];
      apiPositions = apiPositions['hydra:member'];

      let user = await UserAction.getUser();
      let clientId = GeneralAction.iriToId(user.client);

      let positionItems = [];
      for (let position of apiPositions) {
        let alias = await GeneralAction.getAlias(position, clientId);

        positionItems.push(
          <Select.Item label={alias} value={position['@id']} />,
        );
      }
      setPositions(positionItems);

      /* DEPARTMENTS */
      let apiDepartments = requestQueueRes[1];
      apiDepartments = apiDepartments['hydra:member'];

      let departmentItems = [];
      for (let department of apiDepartments) {
        departmentItems.push(
          <Select.Item label={department.name} value={department['@id']} />,
        );
      }
      setDepartments(departmentItems);

      /* EMPLOYEES */
      let employees = requestQueueRes[2];
      employees = employees['hydra:member'];
      let newEmployees = [];
      newEmployees.push({label: 'No employee', value: null});
      for (let employee of employees) {
        newEmployees.push({
          label: `${employee.user.firstName} ${employee.user.lastName}`,
          status: employee?.contractType?.name[selectedLanguage.code],
          value: employee['@id'],
        });
      }
      employee.elements = newEmployees;

      let jobPromosAccepted = [];
      if (props.shift) {
        let result = requestQueueRes[3];

        if (result) {
          jobPromosAccepted = result['hydra:member'];
        }
      }

      let newAcceptedEmployees = [];
      let newOtherEmployees = [];

      for (let apiEmployee of employee.elements) {
        let found = false;
        for (let accepted of jobPromosAccepted) {
          if (
            accepted.employee['@id'] === apiEmployee.value ||
            (props.suggestedEmployee &&
              props.suggestedEmployee['@id'] === apiEmployee.value)
          ) {
            found = true;
          }
        }
        if (found) {
          newAcceptedEmployees.push(apiEmployee);
        } else {
          newOtherEmployees.push(apiEmployee);
        }
      }

      acceptedEmployees.current = newAcceptedEmployees;
      otherEmployees.current = newOtherEmployees;

      /* FORM DATA */

      // get date
      let baseDate = new Date(props.project.startDate);
      let baseStartDate = new Date(baseDate.setHours(9, 0, 0, 0));
      let baseEndDate = new Date(baseDate.setHours(17, 0, 0, 0));

      let newFormData = {
        startOn: new Date(baseStartDate),
        endOn: new Date(baseEndDate),
        quantity: 1,
        project: props.project['@id'],
      };
      formDataRef.startOn = new Date(baseStartDate);
      formDataRef.endOn = new Date(baseEndDate);
      formDataRef.project = props.project['@id'];

      if (props?.departmentId) newFormData['department'] = props.departmentId;

      if (props.shift) {
        newFormData['shiftId'] = props.shift.id;
        formDataRef.shiftId = props.shift.id;
        newFormData['position'] = props.shift.position['@id'];
        formDataRef.position = props.shift.position['@id'];
        newFormData['department'] = props.shift.department?.['@id'];
        formDataRef.department = props.shift.department?.['@id'];
        newFormData['startOn'] = props.shift.startOn;
        formDataRef.startOn = props.shift.startOn;
        newFormData['endOn'] = props.shift.endOn;
        formDataRef.endOn = props.shift.endOn;
        publishShift.current = props.shift.jobPromo
          ? props.shift.jobPromo.active
          : false;

        //Fetch saved employees
        let savedEmployees = await APIAction.request({
          method: 'GET',
          url: '/api/employee_shifts?shiftId=' + props.shift.id,
          cache: false,
        });
        savedEmployees = savedEmployees['hydra:member'];

        let i = 1;
        originalEmployees.employees = [];
        originalEmployees.employeeShifts = [];
        for (let savedEmployee of savedEmployees) {
          formDataRef['position' + i] =
            '/api/employees/' + savedEmployee.employee.id;
          originalEmployees.employees.push(savedEmployee.employee['@id']);
          originalEmployees.employeeShifts.push(savedEmployee['@id']);
          i++;
        }
        if (props.suggestedEmployee) {
          formDataRef['position' + i] = props.suggestedEmployee['@id'];
          i++;
        }
      }

      setFormData(newFormData);

      formDataRef.quantity = props.shift ? props.shift.quantity : 1;

      updateAddEmployees();
    };
    init();
  }, [props, updateAddEmployees]);

  useEffect(() => {
    if (!firstLoad && props.open) {
      onFirstLoad();
      setFirstLoad(true);
    }
  }, [onFirstLoad, firstLoad, props.open]);

  // Submit form
  const submit = async (closeDrawer = true) => {
    let startOn = new Date(formData.startOn);
    let endOn = new Date(formData.endOn);
    let minimumDuration = 3;

    // Make sure start on date is always correct
    let formatter = new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'europe/brussels',
    });

    let currentDate = new Date(props.project.startDate);
    let refDate = formatter.format(new Date(currentDate));
    let refStartOn = formatter.format(new Date(startOn));
    let refEndOn = formatter.format(new Date(endOn));

    let startOnOffset = GeneralAction.tzOffsetToTz(startOn.getTimezoneOffset());
    let endOnOffset = GeneralAction.tzOffsetToTz(endOn.getTimezoneOffset());

    if (refDate !== refStartOn || refDate !== refEndOn) {
      startOn = new Date(startOn.setDate(currentDate.getDate()));
      startOn = new Date(startOn.setMonth(currentDate.getMonth()));
      startOn = new Date(startOn.setFullYear(currentDate.getFullYear()));
      startOn = GeneralAction.changeTimezone(startOn, startOnOffset);
      endOn = new Date(endOn.setDate(currentDate.getDate()));
      endOn = new Date(endOn.setMonth(currentDate.getMonth()));
      endOn = new Date(endOn.setFullYear(currentDate.getFullYear()));
      endOn = GeneralAction.changeTimezone(endOn, endOnOffset);
    }

    // Check order dates, if not right, set next date
    while (startOn > endOn) {
      endOn = new Date(endOn.getTime() + 24 * 60 * 60 * 1000);
    }

    // Put these on top so that we don't have to wait for the request to finish to display an error.
    if (!formDataRef.position) {
      return GeneralAction.toast(
        'error',
        <Trans>Fill in required fields</Trans>,
      );
    } else if (startOn > endOn) {
      return GeneralAction.toast('error', <Trans>Dates in wrong order</Trans>);
    } else if (endOn - startOn > 11 * 60 * 60 * 1000) {
      // If there are more than 11 hours between the start and end date, show an error
      return GeneralAction.toast(
        'error',
        <Trans>Shifts can't be longer than 11 hours</Trans>,
      );
    }

    // Get employee
    let employee = null;

    if (formData.employee) {
      let url = '/api/employees/' + GeneralAction.iriToId(formData.employee);
      employee = await APIAction.request({method: 'GET', url: url});
    }

    // Check client settings: override minimum duration of shift?
    let overrideMinimumDuration = await ClientAction.getClientSetting(
      'override_shift_duration',
    );
    if (overrideMinimumDuration === 'true') {
      minimumDuration = await ClientAction.getClientSetting(
        'minimum_shift_duration',
      );
    }

    // Update minimum duration for Flex-job (to prevent violation of terms FLX)
    /*if (employee) {
                if (employee.contractType && employee.contractType.abbreviation === 'FLX') {
                    minimumDuration = 5;
                }
            }*/

    // Check form data
    // Removed the awaits, because it isn't even an async function .toast
    if (
      new Date(endOn).getTime() - new Date(startOn).getTime() <
      minimumDuration * 60 * 60 * 1000
    ) {
      let errorMessage =
        translations.Minimum +
        ' ' +
        translations.length +
        ': ' +
        minimumDuration +
        ' ' +
        translations.hours;
      GeneralAction.toast('error', errorMessage);
    } else if (
      new Date(startOn).getTime() <=
      new Date(new Date().setMinutes(new Date().getMinutes() + 30))
    ) {
      GeneralAction.toast(
        'error',
        <Trans>Start has to be at least 30 min from current time</Trans>,
      );
    } else if (formData.quantity < 1) {
      GeneralAction.toast(
        'error',
        <Trans>Quantity has to be at least 1</Trans>,
      );
    } else {
      //check if is new or old
      let result = false;
      try {
        if (!formDataRef.shiftId) {
          result = await APIAction.request({
            method: 'POST',
            url: `/api/shifts?publishShift=${publishShift.current}`,
            body: {
              startOn: startOn,
              endOn: endOn,
              position: formDataRef.position,
              department: formDataRef.department,
              quantity: formDataRef.quantity,
              project: formDataRef.project,
            },
            catchFail: false,
          });
        } else {
          result = await APIAction.request({
            method: 'PATCH',
            url: `/api/shifts/${formData.shiftId}?publishShift=${publishShift.current}`,
            body: {
              startOn: startOn,
              endOn: endOn,
              position: formDataRef.position,
              department: formDataRef.department,
              quantity: formDataRef.quantity,
              project: formDataRef.project,
            },
            catchFail: false,
          });
        }
      } catch (e) {
        GeneralAction.toast('error', e);
      }

      if (result === false) {
        GeneralAction.toast('error', <Trans>Cannot make shift</Trans>);
      } else {
        if ('status' in result && result.status === 'NOK') {
          GeneralAction.toast('error', result.message);
        } else {
          if (!formData.shiftId) {
            closeDrawer = true;
            GeneralAction.toast('success', <Trans>Shift created</Trans>);
          } else {
            closeDrawer = true;
            GeneralAction.toast('success', <Trans>Shift updated</Trans>);
          }

          // Save employees
          let quantity = formDataRef.quantity;

          // If employee from new array is not in oldEmployeeArray: POST
          // if employee exists in both array: DO NOTHING
          // If employee from oldEmployeeArray is not in new Array: DELETE (Make ui first)
          for (let i = 1; i <= quantity; i++) {
            //New employee (exists only in formDataRef)
            if (
              formDataRef['position' + i] != undefined &&
              formDataRef['position' + i] != 'No employee' &&
              (originalEmployees.employees == undefined ||
                !originalEmployees.employees.includes(
                  formDataRef['position' + i],
                ))
            ) {
              let response = await APIAction.request({
                method: 'POST',
                url: '/api/employee_shifts',
                body: {
                  employee: formDataRef['position' + i],
                  shift: 'api/shifts/' + result.id,
                },
                cache: false,
              });

              if ('status' in response && response.status === 'NOK') {
                GeneralAction.toast('error', response.message);
                await GeneralAction.sleep(100);
              }
            }
            //Employee already existed (exists in both formDataRef and originalEmployees.employees)
            else if (
              formDataRef['position' + i] != undefined &&
              originalEmployees.employees != undefined &&
              originalEmployees.employees.includes(formDataRef['position' + i])
            ) {
              var indexItemToRemove = originalEmployees.employees.indexOf(
                formDataRef['position' + i],
              );
              originalEmployees.employees.splice(indexItemToRemove, 1);
              originalEmployees.employeeShifts.splice(indexItemToRemove, 1);
            }
          }
          //Delete employees that arent in the form anymore:
          if (originalEmployees.employees) {
            let i = 0;
            for (let employee of originalEmployees.employees) {
              await APIAction.request({
                method: 'PATCH',
                url: originalEmployees.employeeShifts[i],
                body: {
                  employee: employee,
                  shift: 'api/shifts/' + result.id,
                  // @Robbe, I wouldn't recommend setting the deletedAt date in the client side, it's better to do it in the backend. You'll get weird values otherwise and it can be altered.
                  deletedAt: new Date(),
                },
                cache: false,
              });
              i++;
            }
          }

          if (closeDrawer) {
            props.onClose();
          }
          //clear cache
          await RequestCacheAction.clear({url: 'shift'});

          //send reload signal
          if (typeof props.reload === 'function') {
            props.reload();
          }
        }
      }
    }
  };

  return (
    <>
      {null === formData || newShift.loading ? (
        <VStack space={3} p={3}>
          <Skeleton.Text />
          <Skeleton.Text />
          <Skeleton />
        </VStack>
      ) : (
        <VStack space={2}>
          {!props.showEmployeesOnly && (
            <>
              {props.inProjectFlow ? (
                <HStack space={5} alignItems={'center'}>
                  <Box>
                    <Text color={'lightgrey'}>
                      <Trans>Step 1</Trans>
                    </Text>
                    <Text
                      color={'secondary.200'}
                      fontSize={14}
                      fontWeight={'bold'}
                      textTransform={'uppercase'}>
                      <Trans>Create project</Trans>
                    </Text>
                  </Box>
                  <Box
                    backgroundColor={'secondary.600'}
                    h={0.5}
                    flexGrow={1}
                    flexShrink={1}></Box>
                  <Box>
                    <Text>
                      <Trans>Step 2</Trans>
                    </Text>
                    <Text
                      color={'secondary.600'}
                      fontSize={14}
                      fontWeight={'bold'}
                      textTransform={'uppercase'}>
                      <Trans>Create shift</Trans>
                    </Text>
                  </Box>
                </HStack>
              ) : (
                <Box>
                  <Text
                    color={'secondary.600'}
                    fontSize={14}
                    fontWeight={'bold'}
                    textTransform={'uppercase'}>
                    <Trans>Detail shift</Trans>
                  </Text>
                </Box>
              )}
            </>
          )}
          {!props.showEmployeesOnly && (
            <>
              <Stack direction={'row'} space={2}>
                <Box flex={1}>
                  <FormControl>
                    <FormControl.Label>
                      <Text>
                        <Trans>Start</Trans>
                      </Text>
                    </FormControl.Label>
                    <DateTimePicker
                      minimumDate={new Date(props.date).setHours(0, 0, 0, 0)}
                      maximumDate={new Date(props.date).setHours(
                        23,
                        59,
                        59,
                        999,
                      )}
                      mode={'time'}
                      value={new Date(formDataRef.startOn)}
                      onChange={value => {
                        setFormData({...formData, ...{startOn: value}});
                        formDataRef.startOn = value;
                      }}
                    />
                  </FormControl>
                </Box>
                <Box flex={1}>
                  <FormControl>
                    <FormControl.Label>
                      <Text>
                        <Trans>End</Trans>
                      </Text>
                    </FormControl.Label>
                    <DateTimePicker
                      mode={'time'}
                      /*minimumDate={(new Date(new Date(formData.startOn).setHours(new Date(formData.startOn).getHours() + 3)))}*/
                      value={new Date(formDataRef.endOn)}
                      onChange={value => {
                        setFormData({...formData, ...{endOn: value}});
                        formDataRef.endOn = value;
                      }}
                    />
                  </FormControl>
                </Box>
              </Stack>
              <Stack
                direction={{
                  md: 'row',
                  base: 'column',
                }}
                space={{
                  md: 2,
                  base: 4,
                }}>
                <Box flexGrow={1} flexShrink={1} flexBasis={{md: 1}}>
                  <Box>
                    <FormControl.Label>
                      <Text>
                        <Trans>Position</Trans>
                      </Text>
                    </FormControl.Label>
                    <Select
                      placeholder={translations['Select']}
                      selectedValue={formData.position}
                      onValueChange={val => {
                        setFormData({...formData, ...{position: val}});
                        formDataRef.position = val;
                      }}>
                      {positions}
                    </Select>
                  </Box>
                </Box>
                {departments.length > 0 && (
                  <Box flexGrow={1} flexShrink={1} flexBasis={{md: 1}}>
                    <Box>
                      <FormControl.Label>
                        <Text>
                          <Trans>Department</Trans>
                        </Text>
                      </FormControl.Label>
                      <Select
                        placeholder={translations['Select']}
                        selectedValue={formData.department}
                        onValueChange={val => {
                          // setDepartment(val);
                          setFormData({...formData, ...{department: val}});
                          formDataRef.department = val;
                        }}>
                        {departments}
                      </Select>
                    </Box>
                  </Box>
                )}
              </Stack>
              <Stack
                direction={{
                  md: 'row',
                  base: 'column',
                }}
                space={{
                  md: 2,
                  base: 4,
                }}>
                <Box flexGrow={1} flexShrink={1} flexBasis={{md: 1}}>
                  <FormControl>
                    <FormControl.Label>
                      <Text>
                        <Trans>Quantity</Trans>
                      </Text>
                    </FormControl.Label>
                    <NumberInput
                      min={1}
                      value={formDataRef.quantity}
                      onChange={value => {
                        formDataRef.quantity = value;
                        updateAddEmployees();
                      }}
                    />
                  </FormControl>
                </Box>
              </Stack>
              <Box>
                <Checkbox
                  defaultIsChecked={publishShift.current}
                  onChange={val => {
                    publishShift.current = val;
                  }}>
                  <Text>
                    <Trans>Publish for employees</Trans>
                  </Text>
                </Checkbox>
              </Box>
            </>
          )}
          <Box>
            <Text
              color={'secondary.600'}
              fontSize={14}
              fontWeight={'bold'}
              textTransform={'uppercase'}>
              <Trans>Add employees</Trans>
            </Text>
          </Box>
          <HStack flexWrap={'wrap'} justifyContent={'space-between'}>
            {addEmployeeSelects}
          </HStack>
          <Stack
            direction={{
              base: 'column-reverse',
              md: 'row',
            }}
            space={2}>
            <Button
              flex={1}
              variant={'subtle'}
              colorScheme={'secondary'}
              onPress={() => props.onClose()}>
              <Text color={'secondary.600'}>
                <Trans>Close</Trans>
              </Text>
            </Button>
            {!props.shift && (
              <Button
                isLoading={submitLoading}
                flex={1}
                variant={'subtle'}
                onPress={async () => {
                  //Loading buttons
                  setSubmitLoading(true);
                  //Refresh all inputs
                  newShift.loading = true;
                  //Save shift
                  await submit(false);
                  //Clear all data
                  let shiftData = formData['shift'];
                  let baseDate = new Date(props.project.startDate);
                  await setFormData({
                    ...formData,
                    ...{
                      position: undefined,
                      department: undefined,
                      startOn: new Date(baseDate.setHours(9, 0, 0, 0)),
                      endOn: new Date(baseDate.setHours(17, 0, 0, 0)),
                    },
                  });
                  for (let i = 1; i <= formDataRef.quantity; i++) {
                    if (i == 1 && formDataRef['position' + i] != undefined) {
                      formDataRef['position' + i] = undefined;
                    }
                  }
                  formDataRef.quantity = 1;
                  updateAddEmployees();
                  // Quit loading
                  newShift.loading = false;
                  setTimeout(() => {
                    // submit(false);
                    setSubmitLoading(false);
                  }, 1500);
                }}>
                <Text color={'primary.600'}>
                  <Trans>Add another shift</Trans>
                </Text>
              </Button>
            )}
            <Button
              isLoading={submitLoading}
              flex={1}
              variant={'subtle'}
              onPress={() => {
                setSubmitLoading(true);
                setTimeout(() => {
                  submit(false);
                  setSubmitLoading(false);
                }, 1500);
              }}>
              <Text color={'primary.600'}>
                <Trans>Save</Trans>
              </Text>
            </Button>
          </Stack>
        </VStack>
      )}
    </>
  );
};

export default ProjectShiftEditBox;
