import React, {useState, useEffect} from 'react';
import {StyleSheet} from 'react-native';
import {
  Box,
  Divider,
  Text,
  Heading,
  View,
  Stack,
  HStack,
  VStack,
} from 'native-base';
import GeneralAction from '../../Actions/GeneralAction';
import APIAction from '../../Actions/APIAction';
import main from '../../Assets/Styles/main.json';
import TranslationAction from '../../Actions/TranslationAction';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import EmployeeItem from '../Organization/Components/EmployeeItem';
import {v4 as uuidv4} from 'uuid';
import UserAction from '../../Actions/UserAction';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const RowItem = props => {
  const job = props.job;

  //let [logo, setLogo] = useState('');
  let [language, setLanguage] = useState(null);
  let [address, setAddress] = useState([]);
  let [alias, setAlias] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      // Get language
      setLanguage(await TranslationAction.getSelectedLanguage());

      let user = await UserAction.getUser();
      let clientId = GeneralAction.iriToId(user.client);

      if (job.shift && job.shift.position) {
        setAlias(await GeneralAction.getAlias(job.shift.position, clientId));
      }

      // Get address
      if (job.shift && job.shift.address) {
        // By default, pick address of shift
        setAddress(job.shift.address);
      } else if (job.shift && job.shift.project && job.shift.project.client) {
        let client = job.shift.project.client;

        if (client.addressBilling) {
          // Pick billing address of client if we don't have a shift address
          setAddress(client.addressBilling);
        } else if (client.addresses && client.addresses[0]) {
          // Pick first address of client if no billing address
          setAddress(client.addresses[0]);
        }
      }
    };
    fetchData();
  }, [job]);

  return (
    <Box
      style={[
        props.headerStyle ? '' : mainStyle.boxItem,
        {paddingVertical: 10},
      ]}>
      <Box style={{flex: 1}}>
        <Stack
          direction={{
            md: 'row',
            base: 'column',
          }}
          alignItems={{
            md: 'center',
            base: 'flex-start',
          }}
          justifyContent={{
            md: 'space-between',
            base: 'flex-start',
          }}
          space={{
            md: 0,
            base: 2,
          }}
          style={{width: '100%'}}>
          <Stack
            direction={{
              md: 'row',
              base: 'column',
            }}
            alignItems={{
              md: 'center',
              base: 'flex-start',
            }}
            space={{
              md: 4,
              base: 0,
            }}>
            <EmployeeItem
              key={uuidv4()}
              data={job.employee}
              viewOnly={true}
              noPressable={true}
            />
            <Text>{alias}</Text>
          </Stack>

          {props.headerStyle && (
            <Stack
              style={[mainStyle.dateDayLabelWrapper, {flex: 1}]}
              alignItems={{md: 'flex-end', base: 'center'}}>
              <Text style={[mainStyle.dateDayLabel, {marginBottom: 10}]}>
                {GeneralAction.formatDateFull(job.shift.startOn, 'nl')}
              </Text>
            </Stack>
          )}
        </Stack>
      </Box>
      {props.headerStyle && <Divider style={{marginTop: 15}} />}
    </Box>
  );
};

export default RowItem;
