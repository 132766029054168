import {
  AlertDialog,
  useTheme,
  VStack,
  Button,
  Text,
  Icon,
  Spinner,
} from 'native-base';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import TranslationAction from '../../../../Actions/TranslationAction';
import Trans from '../../../Components/Trans';
import GeneralAction from '../../../../Actions/GeneralAction';
import Helpers from '../../../../Libs/Helpers';
import FileFunctions from '../../../../Libs/FileFunctions';

const ProjectDeviationModal = props => {
  const firstRender = useRef(true),
    [awaitDownload, setAwaitDownload] = useState(false),
    [pdfError, setPdfError] = useState(false),
    headerFooterKey = useRef(uuid()),
    {colors} = useTheme(),
    [translations, setTranslations] = useState({
      deviation: '',
      sheet: '',
    }),
    cancelRef = useRef(null);

  const onFirstLoad = useCallback(() => {
    const init = async () => {
      let newTranslations = await TranslationAction.translateInLine(
        Object.keys(translations),
      );
      setTranslations({...translations, ...newTranslations});
    };
    if (translations['deviation'] === '' || translations['sheet'] === '') {
      init();
    }
  }, [setTranslations, translations]);

  const downloadDeviationSheet = async () => {
    setAwaitDownload(true);
    const response = await FileFunctions.download({
        url: `/api/projects/${props.project.id}/deviation-sheets`,
    });

    if (!response || !response.uri) {
        setPdfError(true);
        GeneralAction.toast('error', <Trans>An error occurred</Trans>);
    }

    //open file
    let link = document.createElement('a');
    link.href = response.uri;
    link.download = `deviation-sheet-${new Date().getTime()}.pdf`;
    link.click();    

    setAwaitDownload(false);

    props.onClose();
  };

  useEffect(() => {
    if (firstRender.current && props.isOpen) {
      firstRender.current = false;
      onFirstLoad();
    }
  }, [onFirstLoad, props.isOpen]);

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={props.isOpen}
      onClose={() => props.onClose()}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton />

        <AlertDialog.Header key={headerFooterKey.current + '1'}>
          {Helpers.firstLetterUpperCase(translations['deviation'])}
        </AlertDialog.Header>

        <AlertDialog.Body>
          <VStack>
            {awaitDownload ? (
              <Spinner colorScheme={'primary'} />
            ) : (
              <Text>
                <Trans>Download the deviation sheets</Trans>
              </Text>
            )}
            {!awaitDownload && pdfError && (
              <Text
                color={colors['danger']['600']}
                fontSize={'xs'}
                display={'flex'}>
                <Trans>Something went wrong</Trans>
              </Text>
            )}
          </VStack>
        </AlertDialog.Body>

        <AlertDialog.Footer>
          <Button.Group space={2} key={headerFooterKey.current + '2'}>
            <Button variant={'ghost'} onPress={() => props.onClose()}>
              <Text>
                <Trans>Cancel</Trans>
              </Text>
            </Button>
            <Button
              isLoading={awaitDownload}
              variant={'solid'}
              colorScheme={'primary'}
              onPress={downloadDeviationSheet}>
              <Text color={'#fff'}><Trans>Download</Trans></Text>
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default ProjectDeviationModal;
