import React, {useCallback, useEffect, useRef, useState} from 'react';
import AppContainer from '../Components/AppContainer';
import {Platform, StyleSheet, Dimensions, Pressable} from 'react-native';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import {
  Heading,
  HStack,
  Button,
  Skeleton,
  Stack,
  VStack,
  useBreakpointValue,
  ScrollView,
  Text,
  useTheme,
  Box,
} from 'native-base';
import {Select} from '../../Libs/CustomInputs';
import main from '../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import Trans from '../Components/Trans';
import APIAction from '../../Actions/APIAction';
import Project from './Components/Project';
import ProjectEditPane from './Components/ProjectEditPane';
import {v4 as uuidv4} from 'uuid';
import {Calendar} from 'react-native-calendars';
import GeneralAction from '../../Actions/GeneralAction';
import TranslationAction from '../../Actions/TranslationAction';
import {useRecoilState} from 'recoil';
import ShiftEditPaneAppendState from '../../Recoil/ShiftEditPaneAppendState';
import Jobrequests from '../Components/JobRequests';
import TriggerAction from '../../Actions/TriggerAction';
import Availabilities from '../Components/Availabilities';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const ProjectListScreen = ({navigation, route}) => {
  const [shiftPaneAppend, setShiftPaneAppend] = useRecoilState(
    ShiftEditPaneAppendState,
  );
  const firstLoad = useRef(true);
  const monthTimeout = useRef(null);
  const selectTimeout = useRef(null);
  const fetchTimeout = useRef(null);
  const numberOfWeeksInMonth = useRef(null);
  const cancelProjects = useRef(new AbortController());
  const compact = useBreakpointValue({
      base: true,
      xl: false,
    }),
    [state, setState] = useState({
      newOpen: false,
      projectElements: null,
      projectCount: 0,
      loading: true,
      markedDates: {},
      date: GeneralAction.formatDate(new Date().toISOString(), true),
      selectedDate: new Date(new Date().setHours(12, 0, 0, 0)),
      // When selecting the first week that still has dates in the previous month, the selectedDate will be in the previous month, but for visual purposes, we want a selectedDateForVisuals that is in the visually current month
      selectedDateForVisuals: new Date(
        new Date(new Date()).setHours(0, 0, 0, 0),
      ),
      selectedStartDate: new Date(new Date(new Date()).setHours(12, 0, 0, 0)),
      selectedEndDate: new Date(new Date(new Date()).setHours(12, 0, 0, 0)),
      selectionLevel: 'day',
      translations: {
        Select: null,
        Day: null,
        Week: null,
        Month: null,
      },
      activeScreenJobRequests: true,
    }),
    {colors} = useTheme();
  //change individual item(s) in state
  const changeState = newState => {
    setTimeout(() => {
      setState(prevSate => {
        return {...prevSate, ...newState};
      });
    }, 0);
  };

  //fetch data to display
  const fetchData = useCallback(
    (
      date = state.selectedDate,
      selectionLevel = state.selectionLevel,
      oldMarkedDates = state.markedDates,
    ) => {
      const init = async () => {
        //set abort trigger
        cancelProjects.current.abort();
        cancelProjects.current = new AbortController();
        TriggerAction.setTrigger('projectScreenCancel', cancelProjects.current);

        //get projects
        let reqDate = new Date(new Date(date).setHours(12, 0, 0, 0));
        let projects = await APIAction.request({
          method: 'GET',
          url: '/api/projects',
          params: {
            date: reqDate,
            dateLevel: selectionLevel,
          },
          cache: false,
          abortController: cancelProjects.current,
        });
        if (false === projects) {
          return;
        }
        projects = projects['hydra:member'];

        //init elements
        let projectElements = [];
        let markedDates = JSON.parse(JSON.stringify(oldMarkedDates));

        //first & last date with project (default 1st & last of month)
        let firstProjDate = new Date(date);
        firstProjDate.setDate(1);
        firstProjDate.setHours(0, 0, 0, 0);
        let lastProjDate = new Date(date);
        lastProjDate.setDate(1);
        lastProjDate.setMonth(lastProjDate.getMonth() + 1);
        lastProjDate.setHours(0, 0, 0, 0);
        lastProjDate.setSeconds(0, -1);

        //get dates that are selected
        let selectedDates = {};
        for (let [sDate, value] of Object.entries(markedDates)) {
          if (value.color !== 'transparent') {
            selectedDates[sDate] = value;

            let splitDate = sDate.split('-');

            let pDate = new Date();
            pDate.setFullYear(splitDate[0]);
            pDate.setMonth(splitDate[1] - 1);
            pDate.setDate(splitDate[2]);
            pDate.setHours(0, 0, 0, 0);
            if (pDate < firstProjDate) firstProjDate = new Date(pDate);
            if (pDate > lastProjDate) lastProjDate = new Date(pDate);
          }
        }

        //check project dates
        for (let project of projects) {
          let pStartDate = new Date(project.startDate);
          let pEndDate = new Date(project.endDate);
          if (pStartDate < firstProjDate) firstProjDate = new Date(pStartDate);
          if (pEndDate > lastProjDate) lastProjDate = new Date(pEndDate);
        }

        // //create new marked dates
        // let newMarkedDates = {};
        // let xDay = new Date(firstProjDate);
        // while (xDay <= lastProjDate) {
        //     let formattedXDay = GeneralAction.formatDate(xDay.toISOString(), true);
        //     let preFillPeriods = [];
        //     if (formattedXDay in selectedDates) {
        //         preFillPeriods[0] = selectedDates[formattedXDay].periods[0];
        //     } else {
        //         preFillPeriods[0] = { color: 'transparent' };
        //     }

        //     for (let i = 1; i <= projects.length; i++) {
        //         preFillPeriods[i] = { color: 'transparent' };
        //     }

        //     //update
        //     newMarkedDates[formattedXDay] = { periods: preFillPeriods };

        //     //update date
        //     xDay.setDate(xDay.getDate() + 1);
        // }

        // markedDates = JSON.parse(JSON.stringify(newMarkedDates));

        //loop
        let projectIndex = 1;
        for (let project of projects) {
          //set project element
          projectElements.push(
            <Project
              style={compact ? '' : {marginBottom: 10}}
              key={uuidv4()}
              color={project.color}
              project={project}
              onReload={() => init(date, selectionLevel)}
            />,
          );

          // //set marked dates
          // let currDate = new Date(new Date(project.startDate).setHours(0, 0, 0, 0));
          // let startDate = new Date(new Date(project.startDate).setHours(0, 0, 0, 0));
          // let endDate = new Date(new Date(project.endDate).setHours(0, 0, 0, 0));

          // while (currDate <= endDate) {
          //     let formattedDate = GeneralAction.formatDate(currDate.toISOString(), true);

          //     //create period for calendar
          //     markedDates[formattedDate].periods[projectIndex] = {
          //         color: project.color,
          //         startingDay: currDate.toISOString() === startDate.toISOString(),
          //         endingDay: currDate.toISOString() === endDate.toISOString()
          //     };

          //     //increase
          //     currDate.setDate(currDate.getDate() + 1);
          // }

          //increase
          projectIndex++;
        }

        //set state
        changeState({
          projectElements: projectElements,
          projectCount: projects.length,
          // markedDates: markedDates,
          loading: false,
        });
      };

      init();
    },
    [state.markedDates, state.selectionLevel, state.selectedDate, compact],
  );

  //update the month selected
  const updateMonth = async newDate => {
    if (
      new Date(newDate).toISOString() !==
      new Date(state.selectedDate).toISOString()
    ) {
      clearTimeout(monthTimeout.current);
      monthTimeout.current = setTimeout(() => {
        // updateSelection(newDate, state.selectionLevel);
        updateSelection(newDate, 'month');
        let firstDayOfTheMonth = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          1,
        );
        let lastDayOfTheMonth = new Date(
          newDate.getFullYear(),
          newDate.getMonth() + 1,
          0,
        );

        changeState({
          date: GeneralAction.formatDate(newDate.toISOString(), true),
          //   selectedDate: newDate,
          selectedDateForVisuals: newDate,
          selectedStartDate: firstDayOfTheMonth,
          selectedEndDate: lastDayOfTheMonth,
        });
        clearTimeout(monthTimeout.current);
      }, 1000);

      let totalWeeksInMonth = GeneralAction.getWeeksInMonth(newDate);
      numberOfWeeksInMonth.current = totalWeeksInMonth;
    }
  };

  //update the date that is selected
  const updateSelection = useCallback(
    (selectedDate, selectionLevel) => {
      const init = async () => {
        let calcDate = new Date(selectedDate);
        let calcDateVisual = new Date(JSON.parse(JSON.stringify(calcDate)));
        //set date first of week, when week
        if (selectionLevel === 'week') {
          let dayOfWeek = calcDate.getDay();
          if (dayOfWeek === 0) dayOfWeek = 7;
          dayOfWeek--;
          //   calcDate.setDate(calcDate.getDate() - dayOfWeek);
          calcDate.setHours(12, 0, 0, 0);

          calcDateVisual = new Date(JSON.parse(JSON.stringify(calcDate)));
          const selectedDateMonth = state.selectedDate.getMonth();
          const calcDateMonth = calcDate.getMonth();
          if (selectedDateMonth != calcDateMonth) {
            //select last day of week
            let daysUntilNextSunday = 7 - calcDateVisual.getDay();
            calcDateVisual.setDate(
              calcDateVisual.getDate() + daysUntilNextSunday,
            );
          }
        }

        //set date first of month when month
        if (selectionLevel === 'month') {
          //   calcDate.setDate(1);
          calcDate.setHours(12, 0, 0, 0);
        }

        //clone marked dates
        let markedDates = JSON.parse(JSON.stringify(state.markedDates));

        //empty
        for (let [index, markedDate] of Object.entries(markedDates)) {
          markedDates[index] = {color: 'transparent'};
        }

        //mark selected
        if (selectionLevel === 'week' || selectionLevel === 'day') {
          //get date
          let beginDay = new Date(calcDate);
          let dayCount = 1;

          //if week change vars
          if (selectionLevel === 'week') {
            dayCount = 7;
          }

          //mark al days of that week
          let curSelDate = new Date(beginDay);
          for (let i = 0; i < dayCount; i++) {
            let formattedCurSelDate = GeneralAction.formatDate(
              curSelDate.toISOString(),
              true,
            );
            if (!(formattedCurSelDate in markedDates)) {
              markedDates[formattedCurSelDate] = {color: 'transparent'};
            }

            // Set color to blue for current selected date
            // and establish if it's the startingDay or endingDay
            markedDates[formattedCurSelDate] = {
              color: 'lightblue',
              textColor: 'white',
              startingDay: i === 0,
              endingDay: i === dayCount - 1,
            };

            // Increment date
            curSelDate.setDate(curSelDate.getDate() + 1);
          }
        }

        if (!state.loading) {
          changeState({
            loading: true,
          });
        }

        //fetch new data
        clearTimeout(fetchTimeout.current);
        fetchTimeout.current = setTimeout(() => {
          fetchData(calcDate, selectionLevel, markedDates);
          clearTimeout(fetchTimeout.current);
        }, 1000);

        //set
        let newState = {
          markedDates: markedDates,
          selectionLevel: selectionLevel,
          selectedDate: calcDate,
          selectedDateForVisuals: calcDateVisual,
        };
        if (selectionLevel === 'month') {
          newState['date'] = GeneralAction.formatDate(
            calcDate.toISOString(),
            true,
          );
        }
        changeState(newState);
      };
      clearTimeout(selectTimeout.current);
      selectTimeout.current = setTimeout(() => {
        init();
        clearTimeout(selectTimeout.current);
      }, 500);
    },
    [state.markedDates, fetchData, state.loading, state.selectedDate],
  );

  //only run on mount
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      fetchData();

      //translate
      const translate = async () => {
        let translated = await TranslationAction.translateInLine(
          Object.keys(state.translations),
        );
        changeState({translations: translated});
      };
      translate();
      let firstDayOfTheMonth = new Date(
        state.selectedDate.getFullYear(),
        state.selectedDate.getMonth(),
        1,
      );
      let lastDayOfTheMonth = new Date(
        state.selectedDate.getFullYear(),
        state.selectedDate.getMonth() + 1,
        0,
      );
      let totalWeeksInMonth =
        GeneralAction.getWeekNrFromDate(lastDayOfTheMonth) -
        GeneralAction.getWeekNrFromDate(firstDayOfTheMonth) +
        1;
      numberOfWeeksInMonth.current = totalWeeksInMonth;

      //update selection
      updateSelection(state.selectedDate, state.selectionLevel);
    }
  }, [
    fetchData,
    firstLoad,
    state.translations,
    state.selectedDate,
    updateSelection,
    state.selectionLevel,
  ]);

  const firstColumn = () => {
    return (
      <>
        <Box style={{position: 'relative'}}>
          <Calendar
            initialDate={state.date}
            renderArrow={direction => (
              <Icon size={20} name={'angle-' + direction} />
            )}
            enableSwipeMonths={true}
            firstDay={1}
            showWeekNumbers={true}
            markingType={'period'}
            markedDates={state.markedDates}
            onMonthChange={month => {
              let tDate = new Date(new Date().setFullYear(month.year));
              //   tDate = new Date(tDate.setDate(1));
              let newDate = new Date(tDate.setMonth(month.month - 1));
              newDate = new Date(newDate.setHours(12, 0, 0, 0));
              updateMonth(newDate);
            }}
            onDayPress={day => {
              // let nDate = new Date(new Date(new Date().setFullYear(day.year, day.month - 1, day.day)).setHours(12, 0, 0, 0));
              // updateSelection(nDate, state.selectionLevel);
              changeState({
                selectedStartDate: new Date(day.dateString),
                selectedEndDate: new Date(
                  new Date(day.dateString).setHours(23, 59, 59, 999),
                ),
              });
              updateSelection(day.dateString, 'day');
            }}
          />
          <Button
            style={{
              position: 'absolute',
              left: '20%',
              right: '20%',
              width: '60%',
              backgroundColor: 'none',
              height: 50,
            }}
            onPress={() => {
              let firstDayOfTheMonth = new Date(
                state.selectedDateForVisuals.getFullYear(),
                state.selectedDateForVisuals.getMonth(),
                1,
              );
              let lastDayOfTheMonth = new Date(
                firstDayOfTheMonth.getFullYear(),
                firstDayOfTheMonth.getMonth() + 1,
                0,
              );

              changeState({
                selectionLevel: 'month',
                selectedStartDate: firstDayOfTheMonth,
                selectedEndDate: lastDayOfTheMonth,
              });
              updateSelection(firstDayOfTheMonth, 'month');
            }}></Button>
          {Array(numberOfWeeksInMonth.current)
            .fill()
            .map((_, i) => {
              //1280 is the breakpoint where the calendar changes form full width to next to the projects
              const width =
                Dimensions.get('window').width < 1280
                  ? Dimensions.get('window').width / 8
                  : 50;
              const weeknr =
                GeneralAction.getWeekNrFromDate(
                  new Date(
                    state.selectedDateForVisuals.getFullYear(),
                    state.selectedDateForVisuals.getMonth(),
                    1,
                  ),
                ) + i;

              return (
                <Button
                  style={{
                    position: 'absolute',
                    top: 85 + i * 46,
                    left: '1%',
                    width: width,
                    height: 45,
                    backgroundColor: 'none',
                    borderRadius: 2,
                  }}
                  key={i}
                  title={`Button ${i + 1}`}
                  onPress={() => {
                    let firstDayOfPickedWeek = GeneralAction.getWeekStartEnd(
                      state.selectedDate.getFullYear(),
                      weeknr,
                    ).firstDay;
                    let lastDayOfPickedWeek = GeneralAction.getWeekStartEnd(
                      state.selectedDate.getFullYear(),
                      weeknr,
                    ).lastDay;
                    changeState({
                      selectionLevel: 'week',
                      selectedStartDate: firstDayOfPickedWeek,
                      selectedEndDate: lastDayOfPickedWeek,
                    });
                    updateSelection(firstDayOfPickedWeek, 'week');
                  }}>
                  {/* For debugging */}
                  {/* <Text style={{paddingLeft: 30}}>{ weeknr }</Text> */}
                </Button>
              );
            })}
        </Box>
        {/* <Select
                            placeholder={state.translations['Select']}
                            defaultValue={'month'}
                            selectedValue={state.selectionLevel}
                            onValueChange={(value) => {
                                updateSelection(state.selectedDate, value);
                            }}
                        >
                            <Select.Item label={state.translations['Month']} value={'month'} />
                            <Select.Item label={state.translations['Week']} value={'week'} />
                            <Select.Item label={state.translations['Day']} value={'day'} />
                        </Select> */}
        {Platform.OS === 'web' && (
          <>
            <HStack style={{marginTop: 15}}>
              <Pressable
                style={{width: '50%'}}
                onPress={() => {
                  changeState({activeScreenJobRequests: true});
                }}>
                <Text
                  style={[
                    {
                      color: '#aaa',
                      borderBottomWidth: 2,
                      borderBottomColor: '#aaa',
                    },
                    state.activeScreenJobRequests
                      ? {color: '#00AAFF', borderBottomColor: '#00AAFF'}
                      : {},
                  ]}>
                  <Trans>JOB REQUESTS</Trans>
                </Text>
              </Pressable>
              <Pressable
                style={{width: '50%'}}
                onPress={() => {
                  changeState({activeScreenJobRequests: false});
                }}>
                <Text
                  style={[
                    {
                      color: '#aaa',
                      borderBottomWidth: 2,
                      borderBottomColor: '#aaa',
                    },
                    !state.activeScreenJobRequests
                      ? {color: '#00AAFF', borderBottomColor: '#00AAFF'}
                      : {},
                  ]}>
                  <Trans>AVAILABILITIES</Trans>
                </Text>
              </Pressable>
            </HStack>
            {state.activeScreenJobRequests ? (
              <Jobrequests
                small={true}
                startDate={state.selectedStartDate}
                endDate={state.selectedEndDate}
                onReload={() => fetchData()}
              />
            ) : (
              <Availabilities
                startDate={state.selectedStartDate}
                endDate={state.selectedEndDate}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <AppContainer noScroll={!compact} fullWidth={true}>
        <Stack
          flexGrow={1}
          flexShrink={1}
          space={{
            base: 2,
            xl: 3,
          }}
          direction={{
            base: 'column',
            xl: 'row',
          }}>
          <VStack
            space={2}
            minW={{
              base: null,
              xl: 400,
            }}>
            {compact ? firstColumn() : <ScrollView>{firstColumn()}</ScrollView>}
          </VStack>
          <VStack space={2} flexGrow={1} flexShrink={1}>
            <Box backgroundColor={'#fff'} borderRadius={'md'}>
              <Button
                leftIcon={
                  <Icon
                    size={24}
                    style={{color: colors['secondary']['600']}}
                    name={'plus'}
                  />
                }
                colorScheme={'secondary'}
                onPress={() => {
                  changeState({newOpen: !state.newOpen});
                }}
                variant={'outline'}
                borderRadius={'md'}
                padding={1}>
                <Text color={'secondary.600'}>
                  <Trans>Add project</Trans>
                </Text>
              </Button>
            </Box>

            {compact ? (
              <>
                {state.loading ? (
                  <>
                    <Skeleton h={16} />
                    <Skeleton h={16} />
                  </>
                ) : (
                  <>{state.projectElements}</>
                )}
              </>
            ) : (
              <ScrollView
                style={{flexGrow: 1, flexShrink: 1}}
                alwaysBounceHorizontal={false}
                alwaysBounceVertical={false}>
                <HStack
                  space={2}
                  flexWrap={'wrap'}
                  justifyContent={'space-between'}>
                  {state.loading ? (
                    <>
                      <Skeleton h={16} />
                      <Skeleton h={16} />
                    </>
                  ) : (
                    <>{state.projectElements}</>
                  )}
                </HStack>
              </ScrollView>
            )}
          </VStack>
        </Stack>
      </AppContainer>
      <ProjectEditPane
        key={`new_project_pane`}
        isNewProject={true}
        title={<Trans>New project</Trans>}
        open={state.newOpen}
        date={state.selectedDate}
        onClose={() => {
          changeState({newOpen: false});
        }}
        onReload={() => {
          fetchData(state.selectedDate, state.selectionLevel);
        }}
      />
      {shiftPaneAppend}
    </>
  );
};

export default ProjectListScreen;
